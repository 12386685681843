// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-collocation-servers-js": () => import("./../../../src/pages/collocation-servers.js" /* webpackChunkName: "component---src-pages-collocation-servers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-processing-agreement-js": () => import("./../../../src/pages/data-processing-agreement.js" /* webpackChunkName: "component---src-pages-data-processing-agreement-js" */),
  "component---src-pages-dedicated-servers-js": () => import("./../../../src/pages/dedicated-servers.js" /* webpackChunkName: "component---src-pages-dedicated-servers-js" */),
  "component---src-pages-enefti-ssd-hosting-js": () => import("./../../../src/pages/enefti-ssd-hosting.js" /* webpackChunkName: "component---src-pages-enefti-ssd-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magento-ssd-hosting-js": () => import("./../../../src/pages/magento-ssd-hosting.js" /* webpackChunkName: "component---src-pages-magento-ssd-hosting-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-prestashop-ssd-hosting-js": () => import("./../../../src/pages/prestashop-ssd-hosting.js" /* webpackChunkName: "component---src-pages-prestashop-ssd-hosting-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-domains-js": () => import("./../../../src/pages/register-domains.js" /* webpackChunkName: "component---src-pages-register-domains-js" */),
  "component---src-pages-reseller-ssd-hosting-js": () => import("./../../../src/pages/reseller-ssd-hosting.js" /* webpackChunkName: "component---src-pages-reseller-ssd-hosting-js" */),
  "component---src-pages-sla-quality-agreement-js": () => import("./../../../src/pages/sla-quality-agreement.js" /* webpackChunkName: "component---src-pages-sla-quality-agreement-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-transfer-domains-js": () => import("./../../../src/pages/transfer-domains.js" /* webpackChunkName: "component---src-pages-transfer-domains-js" */),
  "component---src-pages-vps-servers-js": () => import("./../../../src/pages/vps-servers.js" /* webpackChunkName: "component---src-pages-vps-servers-js" */),
  "component---src-pages-web-ssd-hosting-js": () => import("./../../../src/pages/web-ssd-hosting.js" /* webpackChunkName: "component---src-pages-web-ssd-hosting-js" */),
  "component---src-pages-wordpress-ssd-hosting-js": () => import("./../../../src/pages/wordpress-ssd-hosting.js" /* webpackChunkName: "component---src-pages-wordpress-ssd-hosting-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

